//@ts-ignore
//@ts-nocheck


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from 'yup';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import i18next from "i18next";
import { apiCall } from "../../../components/src/APICallComponent/index.web";
import {setProviderUserType} from "../../../components/src/ConditionManager.web";
interface LoginResponseMeta {
  token: string;
  id: string;
  subscription: boolean;
  role: {
    name: string;
  };
  user_name: string;
  complex_name: string;
  society_id: string;
  user_role_societies?: Array<number>;
  dashbord_type: {
    title: string;
  };
}

interface LoginResponse {
  meta?: LoginResponseMeta;
  errors?: Array<Record<string, string>>;
  error?: string;
}

interface LoginState {
  error: string;
  loading: boolean;
  complexModal: boolean;
  showError: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  userType: string | null;
  error: string | null;
  loading: boolean;
  userTypeData:Array[] | null;
  showError:boolean;
  complexModal:boolean;
  complexList:any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ChairmanAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;

  apiEmailLoginCallId: any;
  validationApiCallId: any;
  getUserTypeApiCallId: any;
  apiRegistrationRequestCallId:any ;
  deleteRequestCallId:any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  complexListId: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      error: null,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      userType:'',
      loading: false,
      userTypeData:[],
      showError:false,
      complexModal: false,
      complexList:[],
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  showError = () => {
    if(this.state.error){
      this.setState({
        showError:true
      })
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
        else if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.loginResponse(responseJson)
        }
        else if (apiRequestCallId === this.apiRegistrationRequestCallId) {
          if (responseJson && responseJson?.data ) {
            const  registrationRequest = responseJson?.data[0]
            const status :any = registrationRequest?.attributes?.status;
            if( status === "Requested"){
              this.props.history.push("/ChairmanRegistrationRequest");
              this.setState({registrationRequest, requestdeleteId :registrationRequest.id,loading: false})
            }
            } else if (responseJson?.errors) {
              let error = Object.values(responseJson.errors[0])[0] as string;
              this.setState({ error });
            } else {
              this.setState({ error: responseJson?.error || "Something went wrong!" });
              this.showError()
            }
            this.setState({loading: false})
        }
        else if (apiRequestCallId === this.deleteRequestCallId) {
          if (responseJson.message && responseJson ) {
          this.setState({loading: false,showDialog:false})
          this.props.history.push("/ManagementLogin")
          } else if (responseJson?.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || "Something went wrong!" });
          }
          this.setState({loading: false,showDialog:false})
          this.showError()
        }
        else if (apiRequestCallId === this.getUserTypeApiCallId) {
          if (responseJson && responseJson?.data ) {
          // remove items from array if condition is matched
          const filterRole = responseJson?.data?.filter((item:any) => item.attributes.dashboard_name !== null)
          this.setState({userTypeData :filterRole})
          this.setState({loading: false})
          } else if (responseJson?.errors) {
            let error = Object.values(responseJson.errors[0])[0] as string;
            this.setState({ error });
          } else {
            this.setState({ error: responseJson?.error || "Something went wrong!" });
          }
          this.showError()
          this.setState({loading: false})
        }else if(apiRequestCallId === this.complexListId){
          if(responseJson.hasOwnProperty("complex")){
            this.setState({complexList:responseJson?.complex})
          }
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  loginResponse = async (responseJson: LoginResponse): Promise<void> => {
    try {
      const { meta, errors, error } = responseJson;

      if (!meta?.token) {
        throw new Error(errors ? Object.values(responseJson.errors[0])[0] : error || "Something went wrong!");
      }
      if (!meta.subscription) {
        throw new Error("You do not have an active subscription.");
      }

      const userData = {
        userToken: meta.token,
        userId: meta.id,
        userType: meta.role.name,
        username: meta.user_name,
        complexName: meta.complex_name,
        language: "en",
        society_id: meta.society_id
      };

      Object.entries(userData).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });

      if (!meta.society_id && !meta.user_role_societies) {
        throw new Error("You are not assigned to any complex!");
      }

      const isServiceProvider = meta.dashbord_type.title === "Service Provider";
      const complexCount = meta.user_role_societies ? meta.user_role_societies.length : 0;

      if (complexCount > 1 && !isServiceProvider) {
        this.setState({ complexModal: true });
        await this.getComplexList(meta.role.name);
      } else {
        localStorage.setItem("society_id", meta.society_id);
        setProviderUserType(meta.dashbord_type.title, meta.role.name);
        this.manageNavigation();
      }

    } catch (error) {
      this.setState({
        error: error instanceof Error ? error.message : "Something went wrong!",
        showError: true
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  manageNavigation = () => {
    this.setState({loading: false})
    const userType = this.state.userTypeData
    const localUserType = localStorage.getItem("userType")
    const dashboardType = userType.filter((item)=> item.attributes.name === localUserType)[0]?.attributes?.dashboard_name
    localStorage.setItem("userType",dashboardType?.replace(" ","") || "")
    if(dashboardType === "Chairman"){
      this.props.history.push("/DashboardGeneral")
    }
    else if(dashboardType === "Manager"){
      this.props.history.push("/DashboardGeneral")
    }
    else if(dashboardType === "Security"){
      this.props.history.push("/VisitorList")
    }
    else if(dashboardType === "Auditor"){
      this.props.history.push("/AuditorDashboard")
    }
    else if(dashboardType === "Service Provider"){
      this.props.history.push("/ServiceProviderDashboard");
    }
  }

  handleComplexNavigation = (val:any) => {
    localStorage.setItem("society_id", val.id)
    localStorage.setItem("complexName", val.name)
    this.manageNavigation()
  }

  getComplexList = async (userType:any) => {
    this.complexListId = await apiCall({method: "GET", endPoint: `/bx_block_society_management/society_managements/user_complex_list?user_type=${userType}`,});
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }


  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


clear= () => {
  localStorage.clear()
  this.props.history.push("/ManagementLogin");
}

LoginSchema() {
  const validations = Yup.object().shape({
 email: Yup.string()
 .email('Invalid email format') // Validates proper email structure
 .strict(true)
 .trim()
 .required('Email is required.') // Checks if the email is provided
 .matches(/^[^\s]+$/, 'Email cannot start with or contain spaces'),
    password: Yup.string()
      .required('Password is required.')
      .matches(/^\S*$/, 'Password cannot contain spaces'), 
    userType: Yup.string()
      .required('User Type is required.')
      .trim(),
  });
  return validations;
}   


  doLogIn = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      email: values?.email,
      password: values?.password
    };

    const data = {
      type: "email_account",
      attributes: attrs,
      user_type:values?.userType,
      stay_login: values?.stayIn
    };

    const httpBody = {
      data: data
    };
    localStorage.setItem("selectUserType",values.userType)
    this.setState({loading: true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signinAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  getUserType = () => {
    try {
      const header = {

      };

      //const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getUserTypeApiCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getUserType}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  getRegistrationRequest = () => {
    try {
      const header = {
        token :localStorage.getItem("userToken")
      };

      //const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiRegistrationRequestCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_request_management/requests`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

  deleteRequestById = () => {
    //console.log("this.state?.requestdeleteId deleleleleel}==========>",this.state?.requestdeleteId);
    const id : any = this.state?.requestdeleteId;
    try {
      const header = {
        token :localStorage.getItem("userToken")
      };

      //const id = localStorage.getItem("userId");
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteRequestCallId = requestMessage.messageId;
      this.setState({ loading: true });

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_request_management/requests/${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDelete
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
      console.log(error);
    }
  };
  // Customizable Area End
}

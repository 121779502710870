// Customizable Area Start
import { Grid, styled } from "@material-ui/core";
const ChatDrawerContent = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '100%',
  minWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .chat-header': {
    width: '100%',
    maxHeight: '122px',
    '& .chat-nav': {
      padding: '10px 15px',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e7e8e9',
      '& .header-title': {
        fontWeight: 600,
        fontSize: '16px',
        [theme.breakpoints.up("md")]: {
          fontSize: '18px',
        }
      },
      '& .header-close': {
        width: '25px',
        height: '25px',
        minWidth: '25px',
        '& .header-icon': {
          width: '100%',
          height: '100%',
        },
      },
    },
    '& .chat-detail': {
      padding: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e7e8e9',
      '& .chat-info': {
        width: '55%',
        '& .chat-title': {
          fontWeight: 600,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: '14px',
          [theme.breakpoints.up("md")]: {
            fontSize: '16px',
          }
        },
        '& .chat-id': {
          color: '#181d2580',
          fontWeight: 600,
          fontSize: '10px',
          [theme.breakpoints.up("md")]: {
            fontSize: '12px',
          }
        },
      },
      '& .chat-status': {
        width: '25%',
        borderRadius: '30px',
        padding: '5px 10px',
        '&.pending-confirmation': {
          color: '#fc8434',
          background: '#fc843421',
        },
        '&.ongoing': {
          color: '#2b6fed',
          background: '#e3edff',
        },
        '&.resolved': {
          color: '#20a258',
          background: '#eefbf1',
        },
        '&.unresolved': {
          color: '#fc3e34',
          background: '#ffeded',
        },
        '& .chat-status-text': {
          color: 'inherit',
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: '12px'
        },
      },
    },
  },
  '& .chat-body': {
    padding: 10,
    flex: 1,
    scrollbarWidth: 'thin',
    scrollbarColor: '#2b6fed #f0f0f0',
    overflowY: 'scroll',
    '& .chat-messages': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 40,
      '& .chat-date-box': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        '& .chat-date-separator': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          "& .bg-line": {
            content: "",
            position: 'absolute',
            top: '50%',
            left: '0',
            transform: 'translateY(-50%)',
            width: '100%',
            height: '2px',
            background: '#F2F2F2',
          },
          '& .chat-date': {
            width: 'max-content',
            padding: '5px 15px',
            borderRadius: '20px',
            background: '#F6F6F6',
            color: '#020433',
            fontWeight: 600,
            fontSize: '10px',
            zIndex: 2,
            [theme.breakpoints.up("md")]: {
              fontSize: '12px',
            },
          },
        },
        '& .chat-message-list': {
          display: 'flex',
          // flexDirection: 'column',
          gap: 26,
          '& .chat-message-box': {
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            '& .message-wrapper': {
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              width: '100%',
              "&.is-right": {
                alignItems: 'flex-end',
                '& .chat-message': {
                  borderRadius: '6px 6px 0px 6px',
                }
              },
              "&.is-left": {
                alignItems: 'flex-start',
                '& .chat-message': {
                  borderRadius: '0px 6px 6px 6px',
                }
              },
              '& .chat-message': {
                maxWidth: '60%',
                display: 'flex',
                background: '#F6F6F6',
                color: '#020433',
                padding: 10,
                border: '1px solid #97979721',
                '& .chat-message-text': {
                  wordBreak: 'break-all',
                  padding: 0,
                  fontSize: '12px',
                  [theme.breakpoints.up("md")]: {
                    fontSize: '14px',
                  }
                },
                '& .chat-message-image': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  diplay: 'flex'
                },
                '& .chat-media': {
                  display: 'flex',
                  justifyContent: 'space-between',
                }
              },
              '& .chat-time': {
                display: 'flex',
                '& .chat-time-text': {
                  fontSize: '8px',
                  padding: 0,
                  [theme.breakpoints.up("md")]: {
                    fontSize: '10px',
                  }
                }
              }
            },
          }
        }
      },
      '& .chat-empty': {
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        '& .chat-empty-message': {
          width: '100%',
          textAlign: 'center',
          borderRadius: '28px',
          background: '#F6F6F6',
          color: '#020433',
          padding: 10,
          border: '1px solid #97979721',
          '& .chat-empty-text': {
            fontSize: '14px',
            fontWeight: 600,
            color: 'inherit',
            [theme.breakpoints.up("md")]: {
              fontSize: '16px',
            }
          }
        }
      }
    }
  },
  '& .chat-footer': {
    padding: 10,
    display: 'flex',
    gap: 15,
    alignItems: 'center',
    maxHeight: '72px',
    '& .chat-input-wrapper': {
      width: 'calc(100% - 65px)',
      position: 'relative',
      '& .hidden': {
        display: 'none',
      },
      '& .chat-input': {
        width: '100%',
        '& .MuiFilledInput-root': {
          borderRadius: '28px',
          background: '#F9F9F9',
          border: '1px solid #F0F0F0',
        },
        '& .MuiFilledInput-input': {
          padding: '15px 10px',
          fontSize: '10px',
          [theme.breakpoints.up("md")]: {
            fontSize: '12px',
          },
        },
        '& .MuiInputAdornment-root': {
          margin: 0,
          padding: 0,
        },
        '& .toggle-emoji-picker': {
          padding: 0,
        }
      },
      '& .emoji-picker': {
        position: 'absolute',
        bottom: '55px',
        left: 0,
        zIndex: 5,
      }
    },
    '& .chat-send-button': {
      width: '50px',
      height: '50px',
      minWidth: '50px',
      background: '#2B6FED',
      borderRadius: '50px'
    }
  },

  '& .media-modal': {
    '& .media-modal-content': {
      display: 'flex',
      background: '#FFF',
      maxWidth: '60%',
      height: '60%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
  }
}));
export default ChatDrawerContent;
// Customizable Area End
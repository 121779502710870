// Customizable Area Start
import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

export const LanguageAttributeHandler = (language: any, normalValue: any, arValue: any) => {
  if (language === "ar") {
    return arValue;
  } else {
    return normalValue;
  }
};

export const ErrorHandler = (t: any, touched: any, errors: any) => {
  if (touched && Boolean(errors)) {
    return <small className="error" style={{ marginLeft: "6px" }}>{t(errors)}</small>;
  }
};

export const handleFilterComplexList = (value: number, complexList: any[]) => {
  if (value === 2) {
    return complexList
      .filter((complex) => complex.attributes.is_building)
      .map((item: any) => ({ label: item.attributes.name, value: item.id }));
  } else if (value === 1) {
    return complexList
      .filter((complex) => !complex.attributes.is_building)
      .map((item: any) => ({ label: item.attributes.name, value: item.id }));
  } else {
    return complexList.map((item: any) => ({
      label: item.attributes.name,
      value: item.id,
    }));
  }
};

export const handleFilterMainComplexList = (value: number, complexList: any[]) => {
  if (value === 0) {
    return complexList.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
  } else if (value === 1) {
    return complexList
      .filter((complex) => !complex.is_building)
      .map((item: any) => ({ label: item.name, value: item.id }));
  } else {
    return complexList
      .filter((complex) => complex.is_building)
      .map((item: any) => ({ label: item.name, value: item.id }));
  }
};

export const mockAPICall = (apiData: object, instance: any, apiCallID: string) => {
  const msgSuccessRestAPI = new Message(getName(MessageEnum.RestAPIResponceMessage));
  msgSuccessRestAPI.addData(getName(MessageEnum.RestAPIResponceDataMessage), msgSuccessRestAPI.messageId);
  msgSuccessRestAPI.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), apiData);
  instance[apiCallID] = msgSuccessRestAPI.messageId;
  runEngine.sendMessage("Unit Test", msgSuccessRestAPI);
};

export const handleLink = (link: any) => {
  const aTag = document.createElement("a");
  aTag.href = link;
  aTag.target = "_blank";
  document.body.appendChild(aTag);
  aTag.click();
  if (aTag.parentNode) {
    aTag.parentNode.removeChild(aTag);
  }
};

export const navigationLink = (block: string, context: any) => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), block);
  message.addData(getName(MessageEnum.NavigationPropsMessage), context.props);
  context.send(message);
};

export const emptyFilterValue = (value: string) => (value === "all" ? "" : value);

export const handleNavigation = (link: string) => {
  window.location.href = link;
};

export const dataURLtoFile = (dataurl: any, fileName?: any) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName ? fileName : "file", { type: mime });
};

export const toDataURL = (url: any) =>
  fetch(url)
    .then((response: any) => response.blob())
    .then(
      (blob: any) =>
        new Promise((resolve: any, reject: any) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const handleImages = async (images: any) => {
  const imageUrlPromise: unknown[] = images.map(async (image: { url: string }) => {
    return new Promise(async (resolve, reject) => {
      let blobString = await toDataURL(image.url);
      resolve(blobString);
    });
  });
  const imagesData = await Promise.allSettled(imageUrlPromise);
  return images.map((item: any, index: number) =>
    dataURLtoFile((imagesData[index] as { value: string }).value, item.file_name)
  );
};

export const checkPhoneValidation = (mobile: any, countryCode: any) => {
  let mobileRegex = /^5\d+$/;
  if (mobile.includes("+")) {
    return false;
  } else if (countryCode == "+966" || countryCode == "+971") {
    if (!mobileRegex.test(mobile)) {
      return false;
    } else {
      if (mobile.length === 9) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    if (mobile.length === 10) {
      return true;
    } else {
      return false;
    }
  }
};
// Customizable Area End
